import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";
import "./Footer.css";
import LogoMe from "../../assets/logome5.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-container">
        <ul className="permalinks">
          <li>
            <a href="#home" className="#home" id="homeMenu">
              Home
            </a>
          </li>
          <li>
            <a href="#services" className="#services" id="servicesMenu">
              Services
            </a>
          </li>
          <li>
            <a href="#portfolio" className="#portfolio" id="portfolioMenu">
              Portfolio
            </a>
          </li>

          <li>
            <a href="#partners" className="#partners" id="partnersMenu">
              Partners
            </a>
          </li>
          <li>
            <a href="#contact" className="#contact" id="contactMenu">
              Contact
            </a>
          </li>
          <li>
            <a
              href="#certificates"
              className="#certificates"
              id="certificatesMenu"
            >
              Our experts
            </a>
          </li>
        </ul>
        <div className="footer_socials">
          <a href="https://facebook.com">
            <FaFacebookF />
          </a>
          <a href="https://instagram.com">
            <FiInstagram />
          </a>
          <a href="https://twitter.com">
            <IoLogoTwitter />
          </a>
        </div>

        <div className="footer_copyright">
          <a
            href="https://smichi.netlify.app/"
            className="footer_logo"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoMe} alt="Smichi Inc" id="logo_image-footer" />
          </a>

          <h3>
            Developed by &copy;{" "}
            <a href="https://smichi.netlify.app" target="_blank" rel="noreferrer">
              Mahjoub Smichi 2023
            </a>
            . All rights reserved.
          </h3>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
