import i18n from "i18next";
import english from "./english.json";
import arabic from "./arabic.json";
import francais from "./frensh.json"
import italiano from "./italiono.json"
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//import {I18nManager} from 'react-native';
//import 'intl-pluralrules';

//lng: I18nManager.isRTL ? 'ar' : 'en',
//let locale = StorageTools.getSoredLanguageKey();
//console.log('detected language ==', locale);

//const locale =
//  Platform.OS === 'ios'
//    ? NativeModules.SettingsManager.settings.AppleLocale
//    : NativeModules.I18nManager.localeIdentifier;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: english,
      ar: arabic,
      fr:francais,
      it:italiano
    },
    fallbackLng: "en", // default language
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
