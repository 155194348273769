import React, { useState } from "react";

import "./Portfolio.css";

import Cl0 from "../../assets/cln0.png";
import Cl1 from "../../assets/cln1.png";
import Cl2 from "../../assets/cln2.png";
import Cl3 from "../../assets/cln3.png";
import Cl4 from "../../assets/cln4.png";
import Cl5 from "../../assets/cln5.png";
import Cl6 from "../../assets/cln6.png";
import Cl7 from "../../assets/cln7.png";
import Cl8 from "../../assets/cln8.png";
import Cl9 from "../../assets/cln9.png";
import Po from "../../assets/po5.jpg";

import { VscFolderLibrary } from "react-icons/vsc";
import ModalWindows from "../common/ModalWindows";
import { useTranslation } from "react-i18next";

import ClientCard from "./ClientCard";

const Portfolio = () => {
  const { t } = useTranslation();
  const clients = [
    {
      id: 0,
      name: t("portfolio.client0"),
      task: t("portfolio.task0"),
      image: Cl0,
    },
    {
      id: 1,
      name: t("portfolio.client1"),
      task: t("portfolio.task1"),
      image: Cl1,
    },
    {
      id: 2,
      name: t("portfolio.client2"),
      task: t("portfolio.task2"),
      image: Cl2,
    },
    {
      id: 3,
      name: t("portfolio.client3"),
      task: t("portfolio.task3"),
      image: Cl3,
    },
    {
      id: 4,
      name: t("portfolio.client4"),
      task: t("portfolio.task4"),
      image: Cl4,
    },
    {
      id: 5,
      name: t("portfolio.client5"),
      task: t("portfolio.task5"),
      image: Cl5,
    },
    {
      id: 6,
      name: t("portfolio.client6"),
      task: t("portfolio.task6"),
      image: Cl6,
    },
    {
      id: 7,
      name: t("portfolio.client7"),
      task: t("portfolio.task7"),
      image: Cl7,
    },
    {
      id: 8,
      name: t("portfolio.client8"),
      task: t("portfolio.task8"),
      image: Cl8,
    },
  ];
  const [showDesc, setShowDesc] = useState(false);
  const [selectedClient, setselectedClient] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  return (
    <section id="portfolio" className="container portfolioSection">
      <div className="portfolio-wrapper">
        <div className="portfolio_details">
          <div className="row1">
            <div
              className="titleBox"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="title">
                <VscFolderLibrary size={40} className="icon" />
                <h1>{t("portfolio.title1")}</h1>
              </div>
              <h2>{t("portfolio.desc")}</h2>
            </div>
          </div>
          <div className="row2" data-aos="fade-up" data-aos-duration="1500">
            {clients.map((client) => {
              return (
                <ClientCard
                  Cl1={client.image}
                  clientName={client.name}
                  projectDesc={client.task}
                />
              );
            })}
          </div>
        </div>
      </div>
      <ModalWindows
        show={showDesc}
        handleClose={() => setShowDesc(false)}
        onscrollHandler={() => setShowDesc(false)}
        closeTitle={"Close"}
      >
        <div className="modal-info-style">
          <h1>Client : {clients[selectedClient].name}</h1>
          <h2>Project : {clients[selectedClient].task}</h2>
        </div>
      </ModalWindows>
    </section>
  );
};

export default Portfolio;
