import React from "react";
import "./SlideButton.css";

const SlideButton = ({ title, link,animated=true ,onClickCallBack}) => {
  return (
    <div class="slideButton" data-aos={animated &&"fade-left"} data-aos-duration="1500">
      <a class="slide_from_left" href={link} onClick={onClickCallBack}>
        {title}
      </a>
    </div>
  );
};

export default SlideButton;
