import React from "react";
import "./pulseButton.css";

const PulseButton = ({ link }) => {
  return (
    <a href={link}>
      <div class="square pulse redBtn">
        <h3>Get in touch</h3>
      </div>
    </a>
  );
};

export default PulseButton;
