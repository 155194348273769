import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { CiMenuBurger } from "react-icons/ci";

import SlidingPane from "react-sliding-pane";
//import "react-sliding-pane/dist/react-sliding-pane.css";
import { useTranslation } from "react-i18next";
import i18n from "../../languages/i18n";

import MobileMenu from "./MobileMenu";

const NavBar = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [activeMenu, setActiveMenu] = useState("#home");
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  return (
    <div className={`navbar ${stickyClass}`}>
      <div className="sticky">
        <div className="menuContainer">
          <div className="company">CCN Services</div>

          <div className="menu">
            <a
              href="#home"
              onClick={() => setActiveMenu("#home")}
              className={activeMenu == "#home" && "redMenu"}
              id="homeMenu"
            >
              {t("navBar.home_title")}
            </a>
            <a
              href="#services"
              onClick={() => setActiveMenu("#services")}
              className={activeMenu == "#services" && "redMenu"}
              id="servicesMenu"
            >
              {t("navBar.service_title")}
            </a>
            <a
              href="#portfolio"
              onClick={() => {
                setActiveMenu("#portfolio");
              }}
              className={activeMenu == "#portfolio" && "redMenu"}
              id="portfolioMenu"
            >
              {t("navBar.portfolio_title")}
            </a>

            <a
              href="#partners"
              className={activeMenu == "#partners" && "redMenu"}
              onClick={() => setActiveMenu("#partners")}
              id="partnersMenu"
            >
              {t("navBar.partners_title")}
            </a>
            <a
              href="#contact"
              className={activeMenu == "#contact" && "redMenu"}
              onClick={() => setActiveMenu("#contact")}
              id="contactMenu"
            >
              {t("navBar.contact_title")}
            </a>
            <a
              href="#certificates"
              className={activeMenu == "#certificates" && "redMenu"}
              onClick={() => setActiveMenu("#certificates")}
              id="certificatesMenu"
            >
              {t("navBar.experts_title")}
            </a>
            <a
              href="#about"
              className={activeMenu == "#about" && "redMenu"}
              onClick={() => setActiveMenu("#about")}
              id="aboutMenu"
            >
              {t("navBar.about_title")}
            </a>
          </div>
          {/*<div className="basket">
          <SlBasketLoaded />
          </div>*/}
          <div className="searchBox">{/*<SearchBox />*/}</div>
          <div className="mobileMenu">
            <CiMenuBurger
              size={35}
              onClick={() => setState({ isPaneOpen: true })}
            />
          </div>
        </div>
        {
          <SlidingPane
            className="right-pane-container "
            overlayClassName="Overlay-container"
            isOpen={state.isPaneOpen}
            title="CCNS"
            subtitle=""
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setState({ isPaneOpen: false });
            }}
            width={"60%"}
          >
            <MobileMenu />
          </SlidingPane>
        }
      </div>
    </div>
  );
};

export default NavBar;

/*
<div className="sticky">
      <div className="company">CCNS</div>
      <div className="menu">
        <a href="#">Home</a>
        <a href="#">Portfolio</a>
        <a href="#">Partners</a>
        <a href="#">Services</a>
        <a href="#">Contact</a>
        <a href="#">Certificates</a>
      </div>
    </div>

    */
