import React from "react";
import "./ContactOption.css";
import { MdWhatsapp } from "react-icons/md";
import { BsTelephoneOutbound } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

const ContactOption = ({ title, text1,text2, link1,link2 }) => {
  return (
    <div class="contact-single-features">
      <div className="cardTitle">
        {title === "Email" && <AiOutlineMail size={45} className="icon" />}
        {title === "Phone" && (
          <BsTelephoneOutbound size={45} className="icon" />
        )}
        {title === "Whatsup" && <MdWhatsapp size={45} className="icon" />}
        <h3>{title}</h3>
      </div>

      <div className="cardText">
        <a href={link1} target="_blank" rel="noreferrer">
          {text1}
        </a>
      </div>
      <div className="cardText">
        <a href={link2} target="_blank" rel="noreferrer">
          {text2}
        </a>
      </div>
    </div>
  );
};

export default ContactOption;
