import "./Modal.css";
import SlideButton from "./SlideButton";


const ModalWindows = ({
  handleClose,
  show,
  children,
  onscrollHandler,
  closeTitle,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName} onWheel={onscrollHandler}>
      <div className="modal-main ">
        {children}
        
        <SlideButton title={closeTitle} onClickCallBack={handleClose} animated={false}/>
      </div>
    </div>
  );
};

export default ModalWindows;
