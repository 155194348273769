import React from "react";
import "./slideButtonWhite.css";

const SlideButtonWhite = ({ title, link }) => {
  return (
    <div class="slideButtonWhite" data-aos="fade-left" data-aos-duration="1500">
      <a class="slide_from_left" href={link}>
        {title}
      </a>
    </div>
  );
};

export default SlideButtonWhite;
