import React from "react";
import "./Services.css";
import Img1 from "../../assets/serv8.jpg";
import { RiCustomerService2Fill } from "react-icons/ri";
import ModalWindows from "../common/ModalWindows";
import { useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { BiSolidCheckShield } from "react-icons/bi";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(0);
  const { t } = useTranslation();
  const servicesTasks = [
    {
      id: 0,
      serviceTitle: t("service.serviceTitle0"),
      serviceDesc: t("service.serviceDesc0"),
    },
    {
      id: 1,
      serviceTitle: t("service.serviceTitle1"),
      serviceDesc: t("service.serviceDesc1"),
    },
    {
      id: 2,
      serviceTitle: t("service.serviceTitle2"),
      serviceDesc: t("service.serviceDesc2"),
    },
    {
      id: 3,
      serviceTitle: t("service.serviceTitle3"),
      serviceDesc: t("service.serviceDesc3"),
    },
    {
      id: 4,
      serviceTitle: t("service.serviceTitle4"),
      serviceDesc: t("service.serviceDesc4"),
    },
    {
      id: 5,
      serviceTitle: t("service.serviceTitle5"),
      serviceDesc: t("service.serviceDesc5"),
    },
    {
      id: 6,
      serviceTitle: t("service.serviceTitle6"),
      serviceDesc: t("service.serviceDesc6"),
    },
    {
      id: 7,
      serviceTitle: t("service.serviceTitle7"),
      serviceDesc: t("service.serviceDesc7"),
    },
    {
      id: 8,
      serviceTitle: t("service.serviceTitle8"),
      serviceDesc: t("service.serviceDesc8"),
    },
    {
      id: 9,
      serviceTitle: t("service.serviceTitle9"),
      serviceDesc: t("service.serviceDesc9"),
    },
  ];

  return (
    <section id="services" className="container">
      <div className="services_container">
        <div className="details">
          <div className="title" data-aos="fade-right" data-aos-duration="1500">
            <RiCustomerService2Fill size={40} />
            <h1>{t("service.title1")}</h1>
          </div>
          <p data-aos="fade-right" data-aos-duration="1500">
            {t("service.desc1")}
          </p>
          <p data-aos="fade-right" data-aos-duration="1500">
            {t("service.desc2")}
          </p>
          <ul data-aos="fade-up" data-aos-duration="1500">
            {servicesTasks.map((service) => {
              return (
                <li
                  onClick={() => {
                    setSelectedService(service.id);
                    setShowModal(true);
                  }}
                >
                  <div className="serviceTitle-line">
                    <BiSolidCheckShield
                      size={25}
                      style={{ color: "#d80650" }}
                    />
                    <h3>{service.serviceTitle}</h3>
                  </div>
                 
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <ModalWindows
        show={showModal}
        handleClose={() => setShowModal(false)}
        onscrollHandler={() => setShowModal(false)}
        closeTitle={"Close"}
      >
        <div className="modal-info-style">
          <h1>Service: {servicesTasks[selectedService].serviceTitle}</h1>
          <h2>{parse(servicesTasks[selectedService].serviceDesc)}</h2>
        </div>
      </ModalWindows>
    </section>
  );
};

export default Services;
