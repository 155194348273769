import React, { useState, useRef } from "react";
import SlideButton from "../common/SlideButton";
import ContactOption from "./ContactOption";

import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [captachaDone, setCaptachaDone] = useState(false);
  const [sendButtonActive, setSendButtonActive] = useState(true);
  const form = useRef();
  const { t } = useTranslation();

  const onChange = (value) => {
    //console.log("Site key-done :", process.env.site_key);
    setSendButtonActive(false);

    setCaptachaDone(true);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm(serviceID, templateID, templateParams, publicKey);
    emailjs
      .sendForm(
        process.env.emailjs_serviceID,
        process.env.emailjs_templateID,
        form.current,
        process.env.emailjs_publicKey
      )
      .then(
        (result) => {
          alert("Thank you for your contact, we received your mail 💗");
          console.log("send rs : ", result.text);

          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact">
      <div className="blur-layer">
        <h1>{t("contact.title1")}</h1>
        <div className="contact-wrapper">
          <div className="contact-details">
            <ContactOption
              title={"Email"}
              text1={"contact@ccn-services.com"}
              link1={"mailto:contact@ccn-services.com"}
            />
            <ContactOption
              title={t("contact.phone_title")}
              text1={"Phone 1: +216 22 583 976"}
              link1={"#"}
              text2={"Phone 2: +216 52 376596"}
              link2={"#"}
            />
            <ContactOption
              title={"Whatsup"}
              text1={"+216 542 258"}
              link1={"https://api.whatsup.com/send?phone+216 542 258"}
            />
          </div>
          <div className="contact-container">
            <form
              action=""
              className="contact-form"
              onSubmit={sendEmail}
              ref={form}
            >
              <div className="row1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t("contact.your_name")}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder={t("contact.your_email")}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder={t("contact.your_phone")}
                  required
                />
                <input
                  type="text"
                  name="subject"
                  placeholder={t("contact.message_subject")}
                  required
                />
              </div>
              <div className="row2">
                <textarea
                  name="message"
                  rows="14"
                  placeholder={t("contact.your_message")}
                  required
                ></textarea>
              </div>
              <div className="check-bots">
                <ReCAPTCHA
                  sitekey={process.env.recaptcha_siteKey}
                  onChange={onChange}
                  onExpired={() => {
                    setSendButtonActive(false);
                    setCaptachaDone(false);
                  }}
                />
              </div>

              <div className="send-button">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={sendButtonActive}
                >
                  {t("contact.send_message")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
