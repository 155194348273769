import React from "react";
import "./card.css";
import { BiTargetLock } from "react-icons/bi";
import { MdLocalPolice } from "react-icons/md";

const Card = ({ title, text }) => {
  return (
    <div class="single-features" data-aos="fade-up" data-aos-duration="1500">
      <div className="cardTitle">
       {title==="Vision"? <BiTargetLock size={45} className="icon"/>:<MdLocalPolice size={45} className="icon"/>}
        <h3>{title}</h3>
      </div>

      <div className="cardText">{text}</div>
    </div>
  );
};

export default Card;
