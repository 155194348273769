import React from "react";
import { BsLinkedin, BsFacebook, BsGithub } from "react-icons/bs";
import { IoLogoTwitter } from "react-icons/io";
import { BiLogoInstagram } from "react-icons/bi";
import "./banner.css";

const HeaderSocials = () => {
  return (
    <div className="header_Socials">
      <a href="https://facebook.com" target="_blank" rel="noreferrer">
        <BsFacebook />
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noreferrer">
        <BsLinkedin />{" "}
      </a>
      <a href="https://twitter.com" target="_blank" rel="noreferrer">
        <IoLogoTwitter />
      </a>
      <a href="https://twitter.com" target="_blank" rel="noreferrer">
        <BiLogoInstagram />
      </a>
    </div>
  );
};

export default HeaderSocials;
