import React from "react";
import SC from "../../assets/sc.jpg";
import { BiSolidCheckShield } from "react-icons/bi";
import { FaShieldVirus } from "react-icons/fa";
import { SiSecurityscorecard } from "react-icons/si";

import "./About.css";
//import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section id="about" className="about-section">
      <h1
        className="about-us-title"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        {t("about.about_title")}
      </h1>
      <div className="about-container">
        <div className="row1" data-aos="fade-right" data-aos-duration="1500">
          <div className="picture">
            <img src={SC} alt="ccns" />
          </div>
          <div className="we-are">
            <h2>{t("about.title1")} </h2>
            <h1 style={{ color: "#d80650" }}>CCN Services company</h1>
            <h2>
              <strong> {t("about.title2")} </strong>
            </h2>
            <h3 style={{ textAlign: "justify" }}>{t("about.history")}</h3>
          </div>
        </div>
        <div className="row2" data-aos="fade-up" data-aos-duration="1500">
          <h1 className="history-title">{t("about.main_task")} </h1>

          <div className="details">
            <div className="card">
              <BiSolidCheckShield size={50} color="#d80650" />
              <h2>{t("about.card1_title")} </h2>
              <br />
              <h3 style={{ textAlign: "justify" }}>{t("about.card1_desc")}</h3>
            </div>
            <div className="card">
              <FaShieldVirus size={50} style={{ color: "#d80650" }} />
              <h2>{t("about.card2_title")} </h2>
              <br />
              <h3 style={{ textAlign: "justify" }}>{t("about.card2_desc")}</h3>
            </div>
            <div className="card">
              <SiSecurityscorecard size={50} style={{ color: "#d80650" }} />
              <h2>{t("about.card3_title")} </h2>
              <br />
              <h3 style={{ textAlign: "justify" }}>{t("about.card3_desc")}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
