import React from "react";
import "./header.css";
import "./animatedText.css";


import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import Banner from "../banner/Banner";
import NavBar from "../nav/NavBar";
import PulseButton from "../common/PulseButton";
import SlideButton from "../common/SlideButton";
import SlideButtonWhite from "../common/SlideButtonWhite";
import Card from "../common/Card";
import { useTranslation } from "react-i18next";


const Header = () => {
  const {t} = useTranslation()
  return (
    
    <div className="customHeader area" id="home">
     
      <Banner />
      <NavBar />
      <div className="header_container">
        <div className="row1">
          <div className="leftSide">
            <h2 data-aos="fade-right" data-aos-duration="1500">
              {t("header.title1")}
            </h2>
            <h1 data-aos="fade-right" data-aos-duration="1500">
            {t("header.title2")}
            </h1>

            <div className="headerButton">
              <SlideButton
                title={t("header.get_started")}
                link={"#contact"}
               
              />
              <SlideButtonWhite
                title={t("header.about_us")}
                link={"#about"}
                
              />
            </div>
          </div>
          <div className="rightSide">
            <PulseButton link={"#contact"}/>
          </div>
        </div>
        <div className="row2">
          <div className="card1">
            <Card
              title={"Vision"}
              text={t("header.vision_text")}
            />
          </div>
          
          <div className="card2">
            <Card
              title={"Mission"}
              text={t("header.mission_text")}
            />
          </div>
        </div>
      </div>

      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      {/* For square animation */}
    </div>
  );
};

export default Header;
