import React from "react";

import "./Partners.css";
import { BsCheck2 } from "react-icons/bs";
import Img1 from "../../assets/partners.png";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidCheckShield } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const {t} = useTranslation()
  return (
    <section id="partners">
      <div className="emptySpace"></div>
      <div className="partners_container">
        <div className="details">
          <div className="title" data-aos="fade-right" data-aos-duration="1500">
            <FaUserFriends size={40} />
            <h1>{t("partners.title1")}</h1>
          </div>
        

          <ul data-aos="fade-up" data-aos-duration="1500">
            <li>
              <BiSolidCheckShield className="icon" /> Cisco
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Microsoft
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> VMWARE
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Veritas
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Symantec
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> DELL
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> HP
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> IBM
            </li>
            <li>
              <BiSolidCheckShield className="icon" />
              Fortinet
            </li>
          </ul>
          
        </div>
       
       
      </div>
    </section>
  );
};

export default Partners;
