import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import "./ClientCard.css";
import { useTranslation } from "react-i18next";
const ClientCard = ({ Cl1, clientName, projectDesc }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };
  const {t} = useTranslation()
  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      containerClassName="flip-card"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className="front">
        <div className="client">
          <img src={Cl1} className="client-img" />
          <h2 > {t("client_title" ) }: { clientName} </h2>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClick.bind(this)}
          >
            {t("details_title" ) }
          </button>

          {/*<SlideButton
            title={"Details"}
            animated={false}
            onClickCallBack={handleClick.bind(this)}
  />*/}
        </div>
      </div>
      <div className="back">
        <div className="client">
          <h2 style={{ color: "#d2044d" }}>{t("client_title" ) } : {clientName} </h2>

          <div className="client-desc">
            <h3>{projectDesc}</h3>
            <br />
            <h3>Progress: 100%</h3>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClick.bind(this)}
          >
            Back
          </button>

          {/* <SlideButton
              title={"back"}
              onClickCallBack={handleClick.bind(this)}
              animated={false}
  />*/}
        </div>
      </div>
    </ReactCardFlip>
  );
};

export default ClientCard;
