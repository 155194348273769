import React, { useState } from "react";
import "./mobileMenu.css";
import { useTranslation } from "react-i18next";
import Eng from "../../assets/en.png";
import Fr from "../../assets/fr.png";
import It from "../../assets/it.png";
import i18n from "../../languages/i18n";

const MobileMenu = () => {
  const [activeMenu, setActiveMenu] = useState("#home");
  const { t } = useTranslation();
  return (
    <div className="pane-content">
      <a
        href="#home"
        onClick={() => setActiveMenu("#home")}
        className={activeMenu == "#home" && "redMenu"}
        id="homeMenu"
      >
        {t("navBar.home_title")}
      </a>
      <a
        href="#services"
        onClick={() => setActiveMenu("#services")}
        className={activeMenu == "#services" && "redMenu"}
        id="servicesMenu"
      >
        {t("navBar.service_title")}
      </a>
      <a
        href="#portfolio"
        onClick={() => {
          setActiveMenu("#portfolio");
        }}
        className={activeMenu == "#portfolio" && "redMenu"}
        id="portfolioMenu"
      >
        {t("navBar.portfolio_title")}
      </a>

      <a
        href="#partners"
        className={activeMenu == "#partners" && "redMenu"}
        onClick={() => setActiveMenu("#partners")}
        id="partnersMenu"
      >
        {t("navBar.partners_title")}
      </a>
      <a
        href="#contact"
        className={activeMenu == "#contact" && "redMenu"}
        onClick={() => setActiveMenu("#contact")}
        id="contactMenu"
      >
        {t("navBar.contact_title")}
      </a>
      <a
        href="#certificates"
        className={activeMenu == "#certificates" && "redMenu"}
        onClick={() => setActiveMenu("#certificates")}
        id="certificatesMenu"
      >
        {t("navBar.experts_title")}
      </a>
      <a
        href="#about"
        className={activeMenu == "#about" && "redMenu"}
        onClick={() => setActiveMenu("#about")}
        id="aboutMenu"
      >
        {t("navBar.about_title")}
      </a>
      <div className="lang">
      <img
        src={Eng}
        alt=""
        sizes={15}
        onClick={() => {
          i18n.changeLanguage("en");
        }}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="English"
        data-tooltip-place="left"
      />
      <img
        src={Fr}
        alt=""
        sizes={15}
        onClick={() => {
          i18n.changeLanguage("fr");
        }}
        id="frensh_lng"
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Francais"
        data-tooltip-place="left"
      />
      <img
        src={It}
        alt=""
        sizes={15}
        onClick={() => {
          i18n.changeLanguage("it");
        }}
        id="italy_lng"
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Italiano"
        data-tooltip-place="left"
      />
      </div>
    </div>
  );
};

export default MobileMenu;
