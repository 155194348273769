import React from "react";
import Header from "./components/header/Header";

import Portfolio from "./components/portfolio/Portfolio";
import Footer from "./components/footer/Footer";
import Services from "./components/services/Services";

import Partners from "./components/partners/Partners";
import Contact from "./components/contact/Contact";
import Certificates from "./components/certificates/Certificates";
import About from "./components/about/About";

// ..

//ِAnimation library
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
AOS.init();
// ..

const App = () => {
  return (
    <>
      <Header />
      <div className="emptySpace"></div>
      <Services />
      <div className="emptySpace"></div>
      <Portfolio />
      <div className="emptySpace"></div>
      <Partners />
      <div className="emptySpace"></div>
      <Certificates />
      <div className="emptySpace"></div>
     
      <Contact />
      <div className="emptySpace"></div>
      <About />
      <Footer />
    </>
  );
};

export default App;
