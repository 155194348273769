import React from "react";
import "./banner.css";
import { MdOutlineEmail } from "react-icons/md";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import HeaderSocials from "./HeaderSocials";
import Eng from "../../assets/en.png";
import Fr from "../../assets/fr.png";
import It from "../../assets/it.png";
import i18n from "../../languages/i18n";
import { Tooltip } from "react-tooltip";

const Banner = () => {
  return (
    <div className="banner">
      <a href="mailto:contact@ccn-services.com" className="email">
        <MdOutlineEmail />
        contact@ccn-services.com
      </a>
      <a href="#" className="phone">
        <LiaPhoneVolumeSolid />
        +216 22 583 976, +216 52 376596
      </a>

      <div className="right">
        <div className="lang">
          <img
            src={Eng}
            alt=""
            sizes={15}
            onClick={() => {
              i18n.changeLanguage("en");
            }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="English"
            data-tooltip-place="left"
          />
          <img
            src={Fr}
            alt=""
            sizes={15}
            onClick={() => {
              i18n.changeLanguage("fr");
            }}
            id="frensh_lng"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Francais"
            data-tooltip-place="left"
          />
          <img
            src={It}
            alt=""
            sizes={15}
            onClick={() => {
              i18n.changeLanguage("it");
            }}
            id="italy_lng"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Italiano"
            data-tooltip-place="left"
          />
        </div>
        <HeaderSocials className="socials" />
      </div>
      <Tooltip id="my-tooltip"/>
    </div>
  );
};

export default Banner;
